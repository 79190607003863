import React from "react";

const ListSponsor = () => {
    return (
        <>
            <section id="listSponsor" className=""></section>
        </>
    );
};

export default ListSponsor;
