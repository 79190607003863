export const navigation = [
    {
        name: "Home",
        to: "/",
    },
    {
        name: "Sponsor",
        to: "/soon",
        subMenu: [
            {
                name: "Our Sponsor",
                to: "/soon",
            },
            {
                name: "Become a Sponsor",
                to: "/soon",
            },
        ],
    },
    {
        name: "Partnership",
        to: "/soon",
    },
    {
        name: "About Us",
        to: "/",
        subMenu: [
            // {
            //     name: "Our Team",
            //     to: "/soon",
            // },
            {
                name: "About Us",
                to: "/soon",
            },
            {
                name: "About Comsnets",
                to: "/about-comsnets",
            },
        ],
    },
    {
        name: "Extras",
        to: "/",
        subMenu: [
            {
                name: "Privacy Policy",
                to: "/privacy-policy",
            },
            {
                name: "Disclaimer",
                to: "/disclaimer",
            },
            {
                name: "Contact Us",
                to: "/contact",
            },
        ],
    },
];

export const hero = [
    {
        title: "Comsnets Bali 2023",
        featured: "Gathering In Bali Present",
        // subtitle: " Empowering the Future of Communication Networks and Services",
        desc: "Welcome to Comsnets Bali 2023, the premier event that brings together experts, researchers, and enthusiasts from the world of communication, networks, and security. We're thrilled to have you join us on this exciting journey of discovery, innovation, and collaboration. Get ready to be a part of an incredible community and explore the latest trends, ideas, and breakthroughs in the field. Let's make this Gatheringinbali a memorable experience for all!",
    },
];

export const timer = [
    {
        date: "November 8, 2023, 09:00:00",
    },
];

export const timeLine = [
    {
        index: 1,
        name: "Eps 1 : Social Media Security",
        desc: "some description",
        status: "done",
    },
    {
        index: 2,
        name: "Eps 2 : Cyber Security",
        desc: "some description",
        status: "active",
    },
    {
        index: 3,
        name: "Eps 3 : Digital Footprints",
        desc: "some description",
        status: "locked",
    },
    {
        index: 4,
        name: "Eps 4 : A.I Revolution",
        desc: "some description",
        status: "locked",
    },
    {
        index: 5,
        name: "Eps 5 : Social Media Security",
        desc: "some description",
        status: "locked",
    },
];

export const introEvents = [
    {
        section: "Intro Event",
        title: "Introduction",
        desc1: "Introducing the much-awaited Episode 2 of our event series, focused on the pressing topic of Cyber Security. In today's digital age, with increased dependence on technology and connectivity, the threat of cyber-attacks has become a major concern for individuals and organizations alike. Our event aims to shed light on the various dimensions of cyber security and equip you with the knowledge and tools needed to stay safe in the digital realm. ",
        desc2: "Our conference covers a broad range of topics related to networking and communication, including wireless and mobile communication, network protocols and architectures, multimedia communication, cloud computing, and cybersecurity.",
        desc3: "Our conference features keynote speeches, technical sessions, tutorials, and panel discussions. It is a platform for you to network, share knowledge, and learn from some of the best minds in the field. We hope that you will have a productive and enjoyable time at the conference.",
    },
];

export const activities = [
    {
        section: "Activity",
        title: "activity",
        desc: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Vitae assumenda quaerat, eligendi sunt ratione ipsum.",
        activity: [
            { id: 1, title: "Activity", description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime, cum quos unde iure reiciendis! Sunt praesentium ipsum pariatur corsemir.", icon: "BsChatLeftText" },
            { id: 2, title: "Activity 2", description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime, cum quos unde iure reiciendis! Sunt praesentium ipsum pariatur corsemir.", icon: "BsChatLeftText" },
            { id: 3, title: "Activity 3", description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime, cum quos unde iure reiciendis! Sunt praesentium ipsum pariatur corsemir.", icon: "BsChatLeftText" },
            { id: 4, title: "Activity 4", description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime, cum quos unde iure reiciendis! Sunt praesentium ipsum pariatur corsemir.", icon: "BsChatLeftText" },
            { id: 5, title: "Activity 5", description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime, cum quos unde iure reiciendis! Sunt praesentium ipsum pariatur corsemir.", icon: "BsChatLeftText" },
            { id: 6, title: "Activity 6", description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime, cum quos unde iure reiciendis! Sunt praesentium ipsum pariatur corsemir.", icon: "BsChatLeftText" },
        ],
    },
];

export const eventDetails = [
    {
        section: "Event Information",
        title: "Event Information",
        desc: "Get The Detailed Information About The Event",
        title1: "Date & Time",
        desc1: "8th - 10th November 2023",
        title2: "Location",
        desc2: "Bali, Indonesia",
        title3: "Visa Requirements",
        // desc3: "To attend Gathering and Workshop with Comsnets in Bali 2023, participants will need to purchase a ticket through our official website. We will have a variety of ticketing options available to suit different needs and budgets. Additionally, due to the ongoing COVID-19 pandemic,",
    },
];

export const topic = [
    {
        section: "Event Details",
        title: "What's The Topic ?",
        desc1: "The topic of cybersecurity is of paramount importance in today's increasingly connected world. As we become more reliant on technology and digital communication, the security of our data and systems becomes more critical.",
        desc2: "The cybersecurity track at Gathering and Workshop with Comsnets in Bali 2023 will explore various aspects of cybersecurity, including threats, vulnerabilities, and defenses, in the context of communication systems and networks. The track will cover topics such as network security, data protection, privacy, cryptography, and threat intelligence, among others.",
        desc3: "Attendees will have the opportunity to learn about the latest research and developments in the field, as well as to discuss and share their own insights and experiences.",
    },
];

export const topicImage = [
    {
        src: "/image/cyber-1.jpg",
        alt: "cyber",
    },
    {
        src: "/image/cyber-0.png",
        alt: "cyber",
    },
    {
        src: "/image/cyber-2.jpg",
        alt: "cyber",
    },
    {
        src: "/image/cyber-3.jpg",
        alt: "cyber",
    },
];

export const targetAudience = [
    {
        section: "Event Details",
        title: "Who's The Target Audience ?",
        desc1: "The target audience for COMSNETS includes researchers, academics, and industry professionals who are interested in the field of communication systems and networks.",
        desc2: "Specifically, the conference caters to individuals who are interested in wireless and mobile communication, network protocols and architectures, multimedia communication, cloud computing, and cybersecurity.",
        desc3: " This can include individuals from a range of fields, such as computer science, electrical engineering, telecommunications, and information technology. The conference is open to participants from all over the world who are interested in these areas of research and development.",
    },
];

export const speakers = [
    {
        name: "Jhon Doe",
        job: "CEO",
        corp: "CEO",
        link: {
            instagram: "#",
            facebook: "#",
            linkedin: "#",
        },
        description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Incidunt, inventore autem ratione optio itaque tempora.",
        image: "./image/person1.jpg",
    },
    {
        name: "Jhon Doe",
        job: "CEO",
        corp: "CEO",
        link: {
            instagram: "#",
            facebook: "#",
            linkedin: "#",
        },
        description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Incidunt, inventore autem ratione optio itaque tempora.",
        image: "./image/person1.jpg",
    },
    {
        name: "Jhon Doe",
        job: "CEO",
        corp: "CEO",
        link: {
            instagram: "#",
            facebook: "#",
            linkedin: "#",
        },
        description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Incidunt, inventore autem ratione optio itaque tempora.",
        image: "./image/person1.jpg",
    },
    {
        name: "Jhon Doe",
        job: "CEO",
        corp: "CEO",
        link: {
            instagram: "#",
            facebook: "#",
            linkedin: "#",
        },
        description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Incidunt, inventore autem ratione optio itaque tempora.",
        image: "./image/person1.jpg",
    },
    {
        name: "Kevin Reshard",
        job: "CEO",
        corp: "CEO",
        link: {
            instagram: "#",
            facebook: "#",
            linkedin: "#",
        },
        description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Incidunt, inventore autem ratione optio itaque tempora.",
        image: "./image/person1.jpg",
    },
    {
        name: "Someone Name",
        job: "CEO",
        corp: "CEO",
        link: {
            instagram: "#",
            facebook: "#",
            linkedin: "#",
        },
        description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Incidunt, inventore autem ratione optio itaque tempora.",
        image: "./image/person1.jpg",
    },
    {
        name: "Someone Name 2",
        job: "CEO",
        corp: "CEO",
        link: {
            instagram: "#",
            facebook: "#",
            linkedin: "#",
        },
        description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Incidunt, inventore autem ratione optio itaque tempora.",
        image: "./image/person1.jpg",
    },
];

export const committee = [
    {
        name: "Uday Desai",
        job: "Indian Institute of Technology Hyderabad",
        corp: "India",
        link: {
            instagram: "#",
            facebook: "#",
            linkedin: "#",
        },
        description: "",
        image: "./image/uday_desai.jpg",
    },
    {
        name: "Giridhar Mandyam",
        job: "Qualcomm",
        corp: "USA",
        link: {
            instagram: "#",
            facebook: "#",
            linkedin: "https://www.linkedin.com/in/giridharmandyam/",
        },
        description: "",
        image: "./image/giridhar_mandyam.jpg",
    },
    {
        name: "Rajeev Shorey",
        job: "UQIDAR, Indian Institute of Technology Delhi",
        corp: "India",
        link: {
            instagram: "#",
            facebook: "#",
            linkedin: "https://in.linkedin.com/pub/rajeev-shorey/6/390/894",
        },
        description: "",
        image: "./image/rajeev_shorey.jpg",
    },
    {
        name: "G. Venkatesh",
        job: "Sasken",
        corp: "India",
        link: {
            instagram: "#",
            facebook: "#",
            linkedin: "https://in.linkedin.com/pub/venkatesh-g/0/a58/a02",
        },
        description: "",
        image: "./image/g_venkatesh.jpg",
    },
];

export const pricing = [
    {
        title: "FullBoard",
        price: "9.000.000",
        currency: "Rp",
        frequency: "/person",
        description: "All-inclusive package with hotel stay, meals, and activities.",
        features: ["Accommodation at Hotel Venue", "Full day meeting package", "Merchandise", "Certificate of Attendance", "Coaching and Mentoring Session", "Airport Transfer", "City Tour"],
        cta: "Get Started",
        mostPopular: true,
    },
    {
        title: "FullDay Pass",
        price: "6.000.000",
        currency: "Rp",
        frequency: "/Person",
        description: "Access to all activities and meals (excl. hotel stay).",
        features: ["Full day meeting package", "Merchandise", "Certificate of Attendance", "Coaching and Mentoring Session", "City Tour"],
        cta: "Get Started",
        mostPopular: false,
    },
    {
        title: "Daily Pass",
        price: "2.500.000",
        currency: "Rp",
        frequency: "/Person",
        description: "Have a limited time to attend ? Access to activities for a single day.",
        features: ["Full day meeting package", "Merchandise", "Certificate of Attendance", "Coaching and Mentoring Session"],
        cta: "Get Started",
        mostPopular: false,
    },
    // {
    //     title: "Full Day Pass",
    //     price: "299.000",
    //     currency: "Rp",
    //     frequency: "/ticket",
    //     description: "some short description for product",
    //     features: [
    //         "Acces  to all activity in All Days",
    //         "5 Stars hotel room",
    //         "Free Lunch, Dinner & Snack",
    //         "Free 2 Merchandise",
    //         "Free Certificates",
    //         "Free One Hour Consultation",
    //         "Get Access to backstage ",
    //         "Free Talk to Our Speaker at Backstage",
    //         "Get Special Perks from Bali Islands",
    //     ],
    //     cta: "Get Started",
    //     mostPopular: true,
    // },
];

export const sponsors = [
    {
        gold: [
            {
                id: 1,
                name: "Kementrian Pariwisata",
                description: "The Ministry of Tourism and Creative Economy is the ministry in Indonesia concerned with administration of.",
                description2:
                    "        Lorem, ipsum dolor sit amet consectetur adipisicing elit. Deserunt reiciendis consequatur, expedita cum illum sint a vel quisquam dignissimos eaque excepturi! Alias rem corporis repellat?                ",
                link: {
                    web: "#",
                    instagram: "#",
                    twitter: "#",
                    facebook: "#",
                },
                logo: "./image/kemenparekraf.png",
            },
            {
                id: 2,
                name: "Kementrian Pariwisata",
                description: "The Ministry of Tourism and Creative Economy is the ministry in Indonesia concerned with administration of.",
                description2: "        Lorem, ipsum dolor sit amet consectetur adipisicing elit. Deserunt reiciendis consequatur, expedita cum illum sint a vel quisquam dignissimos eaque excepturi! Alias rem corporis repellat?",
                link: {
                    web: "#",
                    instagram: "#",
                    twitter: "#",
                    facebook: "#",
                },
                logo: "./image/kemenparekraf.png",
            },
            {
                id: 3,
                name: "Kementrian Pariwisata",
                description: "The Ministry of Tourism and Creative Economy is the ministry in Indonesia concerned with administration of.",
                description2: "        Lorem, ipsum dolor sit amet consectetur adipisicing elit. Deserunt reiciendis consequatur, expedita cum illum sint a vel quisquam dignissimos eaque excepturi! Alias rem corporis repellat?",
                link: {
                    web: "#",
                    instagram: "#",
                    twitter: "#",
                    facebook: "#",
                },
                logo: "./image/kemenparekraf.png",
            },
        ],
        silver: [
            {
                id: 4,
                name: "Kementrian Pariwisata",
                link: {
                    web: "#",
                    instagram: "#",
                    twitter: "#",
                    facebook: "#",
                },
                logo: "./image/kemenparekraf.png",
            },
            {
                id: 5,
                name: "Kementrian Pariwisata",
                link: {
                    web: "#",
                    instagram: "#",
                    twitter: "#",
                    facebook: "#",
                },
                logo: "./image/kemenparekraf.png",
            },
            {
                id: 6,
                name: "Kementrian Pariwisata",
                link: {
                    web: "#",
                    instagram: "#",
                    twitter: "#",
                    facebook: "#",
                },
                logo: "./image/kemenparekraf.png",
            },
            {
                id: 7,
                name: "Kementrian Pariwisata",
                link: {
                    web: "#",
                    instagram: "#",
                    twitter: "#",
                    facebook: "#",
                },
                logo: "./image/kemenparekraf.png",
            },
            {
                id: 8,
                name: "Kementrian Pariwisata",
                link: {
                    web: "#",
                    instagram: "#",
                    twitter: "#",
                    facebook: "#",
                },
                logo: "./image/kemenparekraf.png",
            },
            {
                id: 9,
                name: "Kementrian Pariwisata",
                link: {
                    web: "#",
                    instagram: "#",
                    twitter: "#",
                    facebook: "#",
                },
                logo: "./image/kemenparekraf.png",
            },
        ],
        bronze: [
            {
                id: 10,
                name: "Kementrian Pariwisata",
                link: {
                    web: "#",
                    instagram: "#",
                    twitter: "#",
                    facebook: "#",
                },
                logo: "./image/kemenparekraf.png",
            },
            {
                id: 11,
                name: "Kementrian Pariwisata",
                link: {
                    web: "#",
                    instagram: "#",
                    twitter: "#",
                    facebook: "#",
                },
                logo: "./image/kemenparekraf.png",
            },
            {
                id: 12,
                name: "Kementrian Pariwisata",
                link: {
                    web: "#",
                    instagram: "#",
                    twitter: "#",
                    facebook: "#",
                },
                logo: "./image/kemenparekraf.png",
            },
            {
                id: 13,
                name: "Kementrian Pariwisata",
                link: {
                    web: "#",
                    instagram: "#",
                    twitter: "#",
                    facebook: "#",
                },
                logo: "./image/kemenparekraf.png",
            },
            {
                id: 14,
                name: "Kementrian Pariwisata",
                link: {
                    web: "#",
                    instagram: "#",
                    twitter: "#",
                    facebook: "#",
                },
                logo: "./image/kemenparekraf.png",
            },
            {
                id: 15,
                name: "Kementrian Pariwisata",
                link: {
                    web: "#",
                    instagram: "#",
                    twitter: "#",
                    facebook: "#",
                },
                logo: "./image/kemenparekraf.png",
            },
            {
                id: 16,
                name: "Kementrian Pariwisata",
                link: {
                    web: "#",
                    instagram: "#",
                    twitter: "#",
                    facebook: "#",
                },
                logo: "./image/kemenparekraf.png",
            },
            {
                id: 17,
                name: "Kementrian Pariwisata",
                link: {
                    web: "#",
                    instagram: "#",
                    twitter: "#",
                    facebook: "#",
                },
                logo: "./image/kemenparekraf.png",
            },
        ],
    },
];

export const blogs = [
    {
        title: "3 Ways To Become Success",
        description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. repudiandae mollitia tempora molestiae fugit rerum voluptatem animi obcaecati id porro cum totam quis repellat assumenda",
        link: "#",
        thumbnail: "./image/blog1.jpg",
    },
    {
        title: "5 Ways To Become Success",
        description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. repudiandae mollitia tempora molestiae fugit rerum voluptatem animi obcaecati id porro cum totam quis repellat assumenda",
        link: "#",
        thumbnail: "./image/blog1.jpg",
    },
    {
        title: "10 Ways To Become Success",
        description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. repudiandae mollitia tempora molestiae fugit rerum voluptatem animi obcaecati id porro cum totam quis repellat assumenda",
        link: "#",
        thumbnail: "./image/blog1.jpg",
    },
    {
        title: "20 Ways To Become Success",
        description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. repudiandae mollitia tempora molestiae fugit rerum voluptatem animi obcaecati id porro cum totam quis repellat assumenda",
        link: "#",
        thumbnail: "./image/blog1.jpg",
    },
];

export const footer = [
    {
        company: {
            name: "Comsnets",
            street: "A123 Some Street",
            city: "Tanggerang",
            postalCode: "12321",
            country: "Indonesia",
        },
        usefullLink: [
            { name: "Home", link: "/" },
            { name: "About", link: "/about-us" },
            { name: "Contact", link: "/contact-us" },
            { name: "Online Shop", link: "https://shop.gatheringinbali.com" },
            { name: "Privacy Policy", link: "/privacy-policy" },
            { name: "Become Our Sponsor", link: "/become-sponsor" },
        ],
        ourServices: [
            { name: "Marketing", link: "/" },
            { name: "Communication", link: "#" },
            { name: "Some Services", link: "#" },
            { name: "Some Other Services", link: "#" },
        ],
    },
];

export const subFooter = [
    {
        youtube: "https://www.youtube.com/@gathering.inbali/about",
        instagram: "https://instagram.com/gathering.inbali",
        facebook: "https://www.facebook.com/gathering.inbali",
        twitter: "#",
        tiktok: "https://tiktok.com/@gathering.inbali",
        linkedin: "#",
    },
];

// Sponsor
export const features = [
    {
        image: "./image/vector1.png",
        feature: "Some Feature 1",
        desc: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Porro nostrum expedita fugit ad voluptas recusandae rem quo vitae fuga tempora.",
    },
    {
        image: "./image/vector1.png",
        feature: "Some Feature 2",
        desc: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Porro nostrum expedita fugit ad voluptas recusandae rem quo vitae fuga tempora.",
    },
    {
        image: "./image/vector1.png",
        feature: "Some Feature 3",
        desc: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Porro nostrum expedita fugit ad voluptas recusandae rem quo vitae fuga tempora.",
    },
    {
        image: "./image/vector1.png",
        feature: "Some Feature 4",
        desc: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Porro nostrum expedita fugit ad voluptas recusandae rem quo vitae fuga tempora.",
    },
];

export const sponsorLogo = [
    {
        partner: [
            // {
            //     src: "/image/ibm.png",
            //     alt: "IBM Logo",
            //     href: "#",
            // },
            // {
            //     src: "/image/red-hat.svg",
            //     alt: "Red Hut Logo",
            //     href: "#",
            // },
            // {
            //     src: "/image/niagahoster.png",
            //     alt: "Niaga Hoster",
            //     href: "https://niagahoster.com",
            // },
            // {
            //     src: "/image/AMD_Logo.png",
            //     alt: "AMD",
            //     href: "#",
            // },
            // {
            //     src: "/image/ibm.png",
            //     alt: "IBM Logo",
            //     href: "#",
            // },
            // {
            //     src: "/image/red-hat.svg",
            //     alt: "Red Hut Logo",
            //     href: "#",
            // },
        ],
        gold: [
            // {
            //     src: "/image/red-hat.svg",
            //     alt: "Red Hut Logo",
            //     href: "#",
            // },
            // {
            //     src: "/image/ibm.png",
            //     alt: "IBM Logo",
            //     href: "#",
            // },
            // {
            //     src: "/image/red-hat.svg",
            //     alt: "Red Hut Logo",
            //     href: "#",
            // },
            // {
            //     src: "/image/ibm.png",
            //     alt: "IBM Logo",
            //     href: "#",
            // },
        ],
        silver: [
            // {
            //     src: "/image/niagahoster.png",
            //     alt: "Niaga Hoster",
            //     href: "https://niagahoster.com",
            // },
            // {
            //     src: "/image/niagahoster.png",
            //     alt: "Niaga Hoster",
            //     href: "https://niagahoster.com",
            // },
            // {
            //     src: "/image/niagahoster.png",
            //     alt: "Niaga Hoster",
            //     href: "https://niagahoster.com",
            // },
            // {
            //     src: "/image/niagahoster.png",
            //     alt: "Niaga Hoster",
            //     href: "https://niagahoster.com",
            // },
            // {
            //     src: "/image/niagahoster.png",
            //     alt: "Niaga Hoster",
            //     href: "https://niagahoster.com",
            // },
            // {
            //     src: "/image/niagahoster.png",
            //     alt: "Niaga Hoster",
            //     href: "https://niagahoster.com",
            // },
            // {
            //     src: "",
            //     alt: "",
            //     href: "",
            // },
            // {
            //     src: "/image/niagahoster.png",
            //     alt: "Niaga Hoster",
            //     href: "https://niagahoster.com",
            // },
            // {
            //     src: "/image/niagahoster.png",
            //     alt: "Niaga Hoster",
            //     href: "https://niagahoster.com",
            // },
            // {
            //     src: "/image/niagahoster.png",
            //     alt: "Niaga Hoster",
            //     href: "https://niagahoster.com",
            // },
            // {
            //     src: "/image/niagahoster.png",
            //     alt: "Niaga Hoster",
            //     href: "https://niagahoster.com",
            // },
        ],
        bronze: [
            // {
            //     src: "/image/niagahoster.png",
            //     alt: "Niaga Hoster",
            //     href: "https://niagahoster.com",
            // },
            // {
            //     src: "/image/niagahoster.png",
            //     alt: "Niaga Hoster",
            //     href: "https://niagahoster.com",
            // },
            // {
            //     src: "/image/niagahoster.png",
            //     alt: "Niaga Hoster",
            //     href: "https://niagahoster.com",
            // },
            // {
            //     src: "/image/niagahoster.png",
            //     alt: "Niaga Hoster",
            //     href: "https://niagahoster.com",
            // },
            // {
            //     src: "/image/niagahoster.png",
            //     alt: "Niaga Hoster",
            //     href: "https://niagahoster.com",
            // },
            // {
            //     src: "/image/niagahoster.png",
            //     alt: "Niaga Hoster",
            //     href: "https://niagahoster.com",
            // },
            // {
            //     src: "/image/niagahoster.png",
            //     alt: "Niaga Hoster",
            //     href: "https://niagahoster.com",
            // },
            // {
            //     src: "/image/niagahoster.png",
            //     alt: "Niaga Hoster",
            //     href: "https://niagahoster.com",
            // },
            // {
            //     src: "/image/niagahoster.png",
            //     alt: "Niaga Hoster",
            //     href: "https://niagahoster.com",
            // },
            // {
            //     src: "/image/niagahoster.png",
            //     alt: "Niaga Hoster",
            //     href: "https://niagahoster.com",
            // },
            // {
            //     src: "/image/niagahoster.png",
            //     alt: "Niaga Hoster",
            //     href: "https://niagahoster.com",
            // },
            // {
            //     src: "/image/niagahoster.png",
            //     alt: "Niaga Hoster",
            //     href: "https://niagahoster.com",
            // },
            // {
            //     src: "/image/niagahoster.png",
            //     alt: "Niaga Hoster",
            //     href: "https://niagahoster.com",
            // },
            // {
            //     src: "/image/niagahoster.png",
            //     alt: "Niaga Hoster",
            //     href: "https://niagahoster.com",
            // },
            // {
            //     src: "/image/niagahoster.png",
            //     alt: "Niaga Hoster",
            //     href: "https://niagahoster.com",
            // },
            // {
            //     src: "/image/niagahoster.png",
            //     alt: "Niaga Hoster",
            //     href: "https://niagahoster.com",
            // },
            // {
            //     src: "/image/niagahoster.png",
            //     alt: "Niaga Hoster",
            //     href: "https://niagahoster.com",
            // },
            // {
            //     src: "/image/niagahoster.png",
            //     alt: "Niaga Hoster",
            //     href: "https://niagahoster.com",
            // },
        ],
    },
];
